import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function calculatePercentage (oldAvg, newAvg) {
  const diffAmt = parseFloat(oldAvg) - parseFloat(newAvg)
  const totalPercentage = (100 * diffAmt) / parseFloat(newAvg)
  return totalPercentage
}

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('globalTrans.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDivisionName(search.division_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.district'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDistrictName(search.district_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('globalTrans.upazila'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getUpazilaNameCustom(search.upazila_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.market'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getMarketName(search.market_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_group_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.commodity_group_name_bn : search.commodity_group_name_en, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_sub_group'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.commodity_sub_group_name_bn : search.commodity_sub_group_name_en, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.commodity_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.commodity_name_bn : search.commodity_name_en, alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.price_type'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getPriceTypeName(search.price_type_id), alignment: 'left', style: 'search' }
          ],
          [
            { text: vm.$t('crop_price_config.price_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.price_date), alignment: 'left', style: 'search' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['19%', '1%', '80%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('damReport.product_name_and_description'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.measurement'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.today_market_rate'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.previus_week_market_rate'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.percentage_decrease_increase'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.previus_month_market_rate'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.percentage_decrease_increase'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.previus_year_market_rate'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.percentage_decrease_increase'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        newDatas.forEach((info, index) => {
          let unit = ''
          let todayPrice = ''
          let weakPrice = ''
          let weakPercentage = ''
          let monthlyPrice = ''
          let monthlyPercentage = ''
          let yearlyPrice = ''
          let yearlyPercentage = ''
          if (search.price_type_id === 'Wholesale') {
            if (parseFloat(info.w_lowestPrice) || parseFloat(info.w_highestPrice)) {
              todayPrice = vm.$n(parseFloat(info.w_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.weak_w_lowestPrice) || parseFloat(info.weak_w_highestPrice)) {
              weakPrice = vm.$n(parseFloat(info.weak_w_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.weak_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.weak_w_avgPrice)) {
              weakPercentage = vm.$n(parseFloat(calculatePercentage(info.w_avgPrice, info.weak_w_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.month_w_lowestPrice) || parseFloat(info.month_w_highestPrice)) {
              monthlyPrice = vm.$n(parseFloat(info.month_w_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.month_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.month_w_avgPrice)) {
              monthlyPercentage = vm.$n(parseFloat(calculatePercentage(info.w_avgPrice, info.month_w_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.year_w_lowestPrice) || parseFloat(info.year_w_highestPrice)) {
              yearlyPrice = vm.$n(parseFloat(info.year_w_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.year_w_highestPrice).toFixed(2))
            }
            if (parseFloat(info.w_avgPrice) && parseFloat(info.year_w_avgPrice)) {
              yearlyPercentage = vm.$n(parseFloat(calculatePercentage(info.w_avgPrice, info.year_w_avgPrice)).toFixed(2))
            }
            unit = i18n.locale === 'bn' ? info.w_unit_name_bn : info.w_unit_name
          } else {
            if (parseFloat(info.r_lowestPrice) || parseFloat(info.r_highestPrice)) {
              todayPrice = vm.$n(parseFloat(info.r_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.weak_r_lowestPrice) || parseFloat(info.weak_r_highestPrice)) {
              weakPrice = vm.$n(parseFloat(info.weak_r_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.weak_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.weak_r_avgPrice)) {
              weakPercentage = vm.$n(parseFloat(calculatePercentage(info.r_avgPrice, info.weak_r_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.month_r_lowestPrice) || parseFloat(info.month_r_highestPrice)) {
              monthlyPrice = vm.$n(parseFloat(info.month_r_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.month_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.month_r_avgPrice)) {
              monthlyPercentage = vm.$n(parseFloat(calculatePercentage(info.r_avgPrice, info.month_r_avgPrice)).toFixed(2))
            }
            if (parseFloat(info.year_r_lowestPrice) || parseFloat(info.year_r_highestPrice)) {
              yearlyPrice = vm.$n(parseFloat(info.year_r_lowestPrice).toFixed(2)) + '-' + vm.$n(parseFloat(info.year_r_highestPrice).toFixed(2))
            }
            if (parseFloat(info.r_avgPrice) && parseFloat(info.year_r_avgPrice)) {
              yearlyPercentage = vm.$n(parseFloat(calculatePercentage(info.r_avgPrice, info.year_r_avgPrice)).toFixed(2))
            }
            unit = i18n.locale === 'bn' ? info.unit_name_bn : info.unit_name
          }
          allRows.push([
            { text: i18n.locale === 'bn' ? info.commodity_name_bn : info.commodity_name, alignment: 'center', style: 'td' },
            { text: unit, alignment: 'center', style: 'td' },
            { text: todayPrice, alignment: 'center', style: 'td' },
            { text: weakPrice, alignment: 'center', style: 'td' },
            { text: weakPercentage, alignment: 'center', style: 'td' },
            { text: monthlyPrice, alignment: 'center', style: 'td' },
            { text: monthlyPercentage, alignment: 'center', style: 'td' },
            { text: yearlyPrice, alignment: 'center', style: 'td' },
            { text: yearlyPercentage, alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '15%', '15%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('comparative-market-price')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
